import { faEnvelope, faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import { faBuffer } from '@fortawesome/free-brands-svg-icons'

export const usefulLink = [
    {name: 'Home', id: 1},
    {name: 'About us', id: 2},
    {name: 'Services', id: 3},
    {name: 'Team', id: 4},
    {name: 'Blog', id: 5}
]
export const ourServices = [
    {name: 'UX/UI Portfolio', id: 6},
]
export const otherLinks = [
    {name: 'Privacy Policy', id: 13},
    {name: 'Accessibility', id: 14},
]

export const footerInfo = [
    {icon: faBuffer, info1: 'Nine Greenlee', id: 1},
    {icon: faEnvelope, info1: '', info2: 'nineniles@gmail.com', id: 2},
    {icon: faMapMarkedAlt, info1: 'Columbus', info2: 'Ohio, USA', id: 3}
];