import React from 'react';
import { Col, Row } from 'react-bootstrap';
import './Contact.css';
// import swal from 'sweetalert'
import { motion} from "framer-motion";


const Contact = () => {
    
    return (
        
       
       <section id="contact">


            <Col md={11} className="mx-auto">
                <Row>
                    <Col md={6}>
                        
        <motion.div
      
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.8,
        delay: 0.5,
        ease: [0, 0.71, 0.2, 1.01]
      }}
    >
        <h2 className='title'>
            GET IN TOUCH
        </h2>

                            
                                <h4 className="subtitle">This website is crafted by me using React. Learn more about me using my personal chatbot below.</h4>

                                <a href="https://chat-assistant-ui.onrender.com">
                                <button className="contactBtn" type="Learn More">
                            
                                Learn More 
                             </button>
                             </a>

                            
                            </motion.div>
                    </Col>
                  
                </Row>
            </Col>
           
        </section>
        
    );
};

export default Contact;