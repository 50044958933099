import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import FooterCol from './FooterCol';
import './Footer.css';
import { ourServices, footerInfo } from '../../FooterData';
import FooterInfo from './FooterInfo';
import { Link } from 'react-router-dom';
import { scrollUP } from '../../Shared/ScrollTop/ScrollTop';


const Footer = () => {
    return (
        <section className='row footer'>
            <Row className="col-md-11 mx-auto">
                <Row className="align-items-center footerInfo">
                    {
                        footerInfo.map(data => <FooterInfo data={data} key={data.id}/>)
                    }
                </Row>
                <Col md={6} lg={3} className="fAboutUs">
                    <h5>Contact Me</h5>
                    <span className="animate-border"></span>
                    <p className="aboutUsDes">Feel free to talk about literally anything!</p>
                    <ul className="socialIcons">
                    
                        <li>
                            <Link onClick={scrollUP} to="https://discord.gg/DuGR3YcH">
                                <FontAwesomeIcon icon={faDiscord} />
                            </Link>
                        </li>
                        <li>
                            <Link onClick={scrollUP} to="https://www.linkedin.com/in/songsakoongreenlee/">
                                <FontAwesomeIcon icon={faLinkedinIn}/>
                            </Link>
                        </li>
                    </ul>
                </Col>
            
                <FooterCol key="3" menuItems={ourServices} title="Projects"/>
               
            </Row>
            <p className="copyRight">Copyright &copy; 2023 <span className="fHighlight">Nine Greenlee</span>. All rights reserved.</p>
        </section>
    );
};

export default Footer;