export const PricingData = [
    [
        {title: 'Sticky Note Web App' ,name: 'https://note-board-tau.vercel.app', price: 48,
    description: 'A web application to add and delete notes using Node, MondoDB Atlas, and React'},
    {title: 'Personal Assistant Bot' ,name: 'https://chat-assistant-ui.onrender.com', price: 49,
    description: 'An AI bot using OpenAI, Python, and Langchain, to create a perosnal chatbot from my own database.'},
    ],
    [
        {title: 'Healthcare Company Website' ,name: 'http://sbha.dream.press', price: 39,
    description: 'A web design and deployment for non-profit company'},
        {title: 'Youth Blog Website' ,name: 'https://youthhealthhub.org', price: 79,
    description: 'A web design and deployment for youth blog post and service map'},
    ],
    [
        {title: 'The Y Volunteer' ,name: 'https://uxfol.io/p/Ninegreenlee/0364040c', price: 36,
    description:'An application to search and match the volunteering activities'},
        {title: 'Historical AR app' ,name: 'https://uxfol.io/p/Ninegreenlee/0364040b', price: 56,
    description: 'An application for tourists to learn history from landmarks'},
    ]
]