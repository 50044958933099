import { Col, Container, Nav, Row, Tab } from 'react-bootstrap';
import React from 'react';
import PricingCard from './PricingCard';
import './Pricing.css';
import { PricingData } from '../../PricingData';
import sPic1 from '../../../Assets/fullstack.png';
import sPic2 from '../../../Assets/frontend.png';
import sPic3 from '../../../Assets/design.png';
import Spinner from '../../Shared/Spinner/Spinner';

const Pricing = () => {
    return (
        <section id="portfolio" className="pricing">
    
            <div className="text-center">
                <h2 className="sectionTitle">My Projects</h2>
            </div>
            <Container>
                <Tab.Container defaultActiveKey="1"> 
                    <Row>
                        <Col md={10} className="mx-auto">
                            <Nav className="pricingNav">
                                <Nav.Item className="priceLink1">
                                    <Nav.Link eventKey="1">
                                        <img src={`${sPic1}`} alt="" />
                                    </Nav.Link>
                                    <p className='nav-title'>Full Stack</p>
                                </Nav.Item>
                                <Nav.Item className="priceLink2">
                                    <Nav.Link eventKey="2">
                                        <img src={`${sPic2}`} alt="" />
                                    </Nav.Link>
                                    <p className='nav-title'>Front End</p>
                                </Nav.Item>
                                <Nav.Item className="priceLink3">
                                    <Nav.Link eventKey="3">
                                        <img src={`${sPic3}`} alt="" />
                                    </Nav.Link>
                                    <p className='nav-title'>UX Design</p>
                                </Nav.Item>
                                
                            </Nav>
                       </Col>
                        <Tab.Content>
                        {
                            PricingData.length === 0 ?
                            <div className="spinner text-center mt-3"><Spinner/></div>:
                            PricingData.map((data, index) => <PricingCard id={index} data={data} key={index}/>)
                        }
                        </Tab.Content>
                    </Row>
                </Tab.Container>
            </Container>
        </section>

        
    );
};



export default Pricing;