import React from "react";
import './portfolio.css';




function portfolio (){
    return (
        
       
    
        <div className="Port">

          <header className="port-header"> My Portfolio </header>


          </div>
          
    );
}

export default portfolio;