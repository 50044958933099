import React from 'react';
import './About2.css'
import { motion} from "framer-motion";
import cloud1 from "../../../Assets/cloud-1.png";
import cloud2 from "../../../Assets/cloud-2.png";


    function About () {
        const CloudVariants = {
          visible: {
            opacity: 1,
            y: 15,
            transition: {
              opacity: {
                duration: 2,
                ease: "easeInOut",
                delay: 2,
              },
              y: {
                duration: 2,
                repeat: Infinity,
                repeatType: "reverse",
                ease: "easeInOut",
                delay: 4
              }
            }
          },
          hidden: { opacity: 0 }
        }
    
        return (
            <div className="Cloud">
              <header className="cloud-header">
        
            
                <div className='hero-container'>
                <motion.img 
                    src={cloud1}
                    animate={{ scale: 1.2 }}
                    transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }}
                    className="sphere-right"
                    alt="cloud-right"
                  />
                 
                  <motion.img 
                    src={cloud1}
                    animate={{ scale: 1.2 }}
                    transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }}
                    className="star-1 star"
                    alt="star 1"
                  />
                </div>
                <div className="col-md-6 ps-2">
                            
                                <p className="miniTitle">Get to Know</p>
                                <h1 className="headerTitle">A bit more <span className="headerHighlight">About Me</span></h1>
                                <p className="headerContent">My name is Songsakoon Greenlee and I  graduated from New York University with a Master's degree in Digital Media Design for Learning and a minor in Game Design. I possess a strong background in design and user research, and have experience creating user-centered solutions. 
                                I make interactive product prototypes, develop websites, test and re-develope (repeat!).</p>
                            
                        </div>
                <motion.img
                  variants={CloudVariants}
                  initial="hidden"
                  animate="visible"
                  src={cloud2}
                  className="cloud-1"
                  alt="cloud left"
                />

<motion.img
                  variants={CloudVariants}
                  initial="hidden"
                  animate="visible"
                  src={cloud2}
                  className="cloud-2"
                  alt="cloud left"
                />
        
              </header>
            </div>

          );
        }

export default About;