import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';





const data = [
    {
        title: 'Full Stack Development',
        subtitle:
            'With expertise in both front-end and back-end technologies, I develop dynamic and scalable web applications. From designing user interfaces to managing databases, I bring together the complete stack to deliver seamless and efficient solutions.',
        icon: 'https://imgur.com/pwkEqiv.png',
    },
    {
        title: 'Front End Development',
        subtitle:
            'As a front-end developer, I create engaging and responsive user interfaces. Proficient in HTML5, CSS, Bootstrap, React, and JavaScript frameworks, I ensure a seamless user experience by translating designs into interactive websites that captivate users and meet their needs.',
        icon: 'https://imgur.com/eUL69Yz.png',
    },
    {
        title: 'UX/UI Design',
        subtitle:
            'With a focus on user-centered design, I craft intuitive and visually appealing interfaces. By conducting user research, wireframing, and prototyping, I create designs that optimize usability and enhance user satisfaction, resulting in meaningful and delightful digital experiences.',
        icon: 'https://imgur.com/Q5g7af9.png',
    },
    {
        title: 'Project Management',
        subtitle:
            'Through effective planning, coordination, and communication, I maintain strict adherence to timelines, allocate resources efficiently, and mitigate risks. ',
        icon: 'https://imgur.com/9fWKJJ4.png',
    },
];

const BrowserSupport = () => {
    return (
        <Box p={2} mb={2}>
            <Box marginBottom={10} marginTop={10} textAlign={'center'}>
                <Typography
                    sx={{
                        textTransform: 'uppercase',
                        fontWeight: 'medium',
                    }}
                    gutterBottom
                    color={'primary'}
                >
                    Area of Expertise
                </Typography>
                <Typography fontWeight={700} 
                variant={'h2'}
                sx={{
                    fontFamily: 'Roboto, sans-serif', 
                  }}
                >
                    What I can do
                </Typography>
            </Box>
            <Grid container spacing={4}>
                {data.map((item, i) => (
                    <Grid item xs={12} md={3} key={i}>
                        <Box
                            width={1}
                            height={1}
                            data-aos={'fade-up'}
                            data-aos-delay={i * 100}
                            data-aos-offset={100}
                            data-aos-duration={600}
                        >
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                alignItems={'center'}
                            >
                                <Box
                                    component={Avatar}
                                    width={80}
                                    height={80}
                                    marginBottom={2}
                                    src={item.icon}
                                />
                                <Typography
                                    variant={'h5'}
                                    gutterBottom
                                    color={'primary'}
                                    fontWeight={500}
                                    align={'center'}
                                >
                                    {item.title}
                                </Typography>
                                <Typography align={'center'} color="text.secondary">
                                    {item.subtitle}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}

export default BrowserSupport
