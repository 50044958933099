import React from 'react';
import {Oval} from 'react-loader-spinner';

const Spinner = () => {
    return (
        <Oval
        color="#7456F7"
        height={70}
        width={70}
      />
    );
};

export default Spinner;