import React from 'react';
import { Col, Row, Tab } from 'react-bootstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { motion} from "framer-motion";

const PricingCard = ({data, id}) => {
    return (
        <Tab.Pane eventKey={id + 1}>
            <Row>
                {
                    data.map(({title, name, price, description}, index) => {
                        return(
                            <Col md={4} key={index}>
                               
                               <motion.div
      
                                 initial={{ opacity: 0, scale: 0.5 }}
                                animate={{ opacity: 1, scale: 1 }}
                                transition={{
                                duration: 0.8,
                                delay: 1.5,
                                 ease: [0, 0.71, 0.2, 1.01]
                                }}
                                >
                                    <div className={`pricingCard pricingCard${id + 1}`}>
                                        <div className="pricingBox">
                                            <h4>{title}</h4>
                                           
                                
                                
                                            <p className="planDescription">{description}</p>
                                        </div>
                                        <li>
                                            <span className="checkIcon">

                                            {/* <FontAwesomeIcon icon={faCheckCircle}/> */}
                                            </span> Demo
                                        </li>
                                        <li>
                                            <span className="checkIcon">
                                            {/* <FontAwesomeIcon icon={faCheckCircle}/> */}
                                            <a href={name} target="_blank" rel="noopener noreferrer">
                                        {name}
                                        </a>
                                            
                                            </span>
                                        </li>
                                        <li>
                                            <span className="checkIcon">
                                            {/* <FontAwesomeIcon icon={faCheckCircle}/> */}
                                            </span> 
                                        </li>
                                    </div>
                               </motion.div>

                            </Col>
                        )
                    })
                }
            </Row>
        </Tab.Pane>
    );
};

export default PricingCard;