import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { alpha, createTheme, ThemeProvider} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typed from 'react-typed';
import './Hero.css';

const Hero = () => {
    const theme = createTheme({
        typography: {
          fontFamily: 'monospace',
          h1: {
            fontFamily: 'Inter'
          },
        },
      });

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });
    return (

        <Grid container spacing={4}>
            <Grid item container xs={12} md={6} alignItems={'center'}>
                <Box data-aos={isMd ? 'fade-right' : 'fade-up'} paddingLeft={isMd && 2} paddingRight={isMd && 2} p={1}>
                   
                    <Box marginBottom={2}>
                    <ThemeProvider theme={theme}>
                        <Typography 
                            variant="h3"
                            color="#f5f5f5"
                            paddingLeft={5}
                            sx={{ fontWeight: 700 }}
                        >
                            My Name is Nine Greenlee <br />
                            I am a  
                            <span
                         style={{
                         background: `linear-gradient(180deg, transparent 82%, ${alpha(theme.palette.secondary.main, 0.3)} 0%)`,
                        color: "#ea8685",
                         display: 'inline-flex', 
                        alignItems: 'center',
                        height: '1.5em', 
                        overflow: 'hidden',
                        marginLeft: '20px'
                        }}
                        >
                        <Typed
                        className="typed-font"
                        strings={[' Web Developer', 'UXUI Designer']}
                        typeSpeed={100}
                        loop={true}
                        />
                        </span>
                        </Typography>
                        </ThemeProvider>
                    </Box>
                    <Box marginBottom={3}>
                        <Typography variant="h5" component="p" color="#f5f5f5"  paddingLeft={5}>
                        I'm a user-centered designer and developer. With experience in development and business, I love making ideas come to reality!
                            <br />
                            Embrace challenging and learning with joy 😃
                        </Typography>
                    </Box>
                    <Box
                        display="flex"
                        flexDirection={{ xs: 'column', sm: 'row' }}
                        alignItems={{ xs: 'stretched', sm: 'flex-start' }}
                        paddingLeft={5}
                    >
                        
                        
                        <Button
                            variant="contained"
                            backgroundColor="ea8985"
                            sx={{
                                '&:hover': {
                                  backgroundColor: '#05445E',
                                },
                              }}
                            size="large"
                            fullWidth={isMd ? false : true}
                            a href="mailto:nineniles@gmail.com"
                        >
                            Contact Me
                        </Button>
            
                    </Box>
                </Box>
            </Grid>
            <Grid
                item
                container
                alignItems={'center'}
                justifyContent={'center'}
                xs={12}
                md={6}
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
            >
                <Box
                    component={LazyLoadImage}
                    width={300}
                    height={300}
                    a href="https://imgur.com/5z8S6pT"
                    img src="https://i.imgur.com/5z8S6pT.png"
                    alt="..."
                    effect="blur"
        
                />
            </Grid>
        </Grid>
    )
}

export default Hero
